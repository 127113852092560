<template>
  <div :class="$store.state.tyPhone == 'ios'?'home iosList':'home'">
    <!-- -->
    <div class="home_top">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="i in images" :key="i.id">
          <img v-lazy="i.bannerPhoto" @click="btn(i.linkUrl)">
        </van-swipe-item>
      </van-swipe>
    </div>
    <navItem></navItem>
    <component v-for="(item,index) in componentList"  :key="index" :is="item.name" :dataFrom="dataFrom" :relaId="item.relaProm"></component>
  </div>
</template>
<script>
import education from '@/views/home/homeItem/education.vue'
import handleAffairs from '@/views/home/homeItem/handleAffairs.vue'
import navItem from '@/views/home/homeItem/nav.vue'
import nk_consulting from '@/views/home/homeItem/nk_consulting.vue'
import sceneService from '@/views/home/homeItem/sceneService.vue'
import Information from '@/views/home/homeItem/Information.vue'
import weather from '@/views/home/homeItem/weather.vue'
import advertising from '@/views/home/homeItem/advertising.vue'
import { home,swiper,recordLogin } from "@/api/home.js";
import axios from 'axios'
export default {
  name:"home",
  data() {
    return {
      tittle:'津心办·南开旗舰店',
      dataFrom:[],
      arr:[],
      list:["weather", "advertising", "education", "handleAffairs", "sceneService", "nk_consulting", "Information","advertising"],
      // tab栏切换
      tab_state:1,
      // 轮播
      images: [],
      // 遍历样式
      componentId:[
        {
          name:"advertising",
          id:0
        },
        {
          name:"weather",
          id:1
        },
        {
          name:"sceneService",
          id:2
        },{
          name:"handleAffairs",
          id:3
        },
        {
          name:"education",
          id:4
        },
        {
          name:"nk_consulting",
          id:5
        },
        {
          name:"Information",
          id:99
        }
      ],
      // 首页顺序
      componentList:[],
      arr:[]
    }
  },
  components:{
    education,
    handleAffairs,
    navItem,
    nk_consulting,
    sceneService,
    weather,
    advertising,
    Information
  },
  computed:{
  },
  mounted(){
    this.init()
    this.loginUser()
    if(this.$store.state.jxbApp){
      window.location.href=`ssptitle://${this.tittle}`
    }else{
      document.title = this.tittle
    }
  },
  methods:{
    btn(url){
      if(url){
        window.location.href = url
      }
    },
    async init(){
      try {
        const result = await home();
        const imgList = await swiper();
        this.images = imgList.data.data
        for(let i in result.data.data){
          if(result.data.data[i].navCode == 99 || result.data.data[i].navCode == 0){
            this.arr.push(result.data.data[i])
            this.$store.commit('setAdvertisingId',this.arr)
          }
          this.componentId.map((item,index)=>{
             if(item.id == result.data.data[i].navCode){
              let menuList = {
                name:item.name,
                relaProm:result.data.data[i].relaProm
              }
              this.componentList.push(menuList)
             }
          })
        }
      } catch (error) {
        this.$toast.fail('网络问题');
      }
    },
     async loginUser(){
      // 津心办登录接口
          let _this = this
            lightAppJssdk.userTicket.getUserTicket({
            success: function(data) {
              lightAppJssdk.request.request({
                url:'http://jxb.tj.gov.cn/tjzw/interfaces/getCacheUser.do?ticket='+data.ticket, //请求地址
                header:'', //请求头设置
                dataType:'json', //返回数据格式，默认json
                async:false, //同步设置
                success: function(data){ //成功回调
                  lightAppJssdk.aesRsa.hanwebAESDecodeWithParam({
                    contentStr:data.user,
                    success:async function(data) {
                      var obj = JSON.parse(data.result)
                      if(sessionStorage.getItem('homeName') == '' || sessionStorage.getItem('homeName') !== obj.USER_ID){
                        if(obj.USER_ID || obj.USER_NAME){
                          sessionStorage.setItem('homeName',obj.USER_ID)
                          await axios({
                            url: `https://nkstoreht.tiptimes.com/api/home/recordLogin`,
                            method: 'POST',
                            headers:{
                                userId:obj.USER_ID,
                                userName:encodeURIComponent(obj.USER_NAME)
                            }
                          }).then((res)=>{
                            console.log(res)
                          })
                        }
                      }
                    },
                    fail: function(data) {
                      console.log(data);
                    }
                  });
                },
                fail:function(data){
                  // alert(data);
                }
              });
            },
            fail: function(data) {
              console.log(data);
            }
          });
        
    }
  }
}
</script>
<style lang="less" scoped>
  @import './home.less';
</style>