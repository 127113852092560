<template>
  <div class="sceneServices">
    <div class="bac_style"></div>
    <div class="sceneService">
      <div class="sceneServiceLeft" @click="scenario(1)"></div>
      <div class="sceneServiceRight" @click="scenario(2)"></div>
    </div>
  </div>
</template>

<script>
export default {
methods:{
  // 场景式服务
    scenario(id){
      this.$router.push({path:'/personal',query:{id:id}})
    }
}
}
</script>

<style lang="less" scoped>
  @import '../home.less';
</style>