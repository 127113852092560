<template>
  <div class="navkey">
      <div class="nav">
          <div class="navWalk" @click="btnOpen(i)" v-for="i in navBtn" :key="i.id">
          <img :src="i.columnIcon" alt="">
          <div>{{i.columnName}}</div>
        </div>
      </div>
  </div>
</template>

<script>
import { getContentMenuList } from '@/api/home.js';
export default {
  data() {
    return {
      // 
      navBtn:[
        {
          id:1,
          tittle:'走进南开',
          images:'zj_nankai'
        },
        {
          id:2,
          tittle:'创新南开',
          images:'cx_nankai'
        },
        {
          id:3,
          tittle:'投资南开',
          images:'tz_nankai'
        }
      ],
    }
  },
  created(){
    this.init()
  },
  methods:{
    async init(){
      try {
        let list = await getContentMenuList({
          "pageNumber": 1,
          "pageSize": 3
        })
        this.navBtn = list.data.data.rows
      } catch (error) {
        
      }
    },
    // 走进南开
    async btnOpen(item){
      if(item.columnType == 3){
          // lightAppJssdk.weex.openWeexVc({
          //   title :item.columnName,//顶部导航栏标题
          //   url : item.columnLink, //weex页面地址
          //   success: function(data) {
          //       console.log(data);
          //   },
          //   fail: function(data) {
          //       console.log('falied');
          //   }
          //   });
          location.href = item.columnLink
        }else{
          this.$store.commit('setTopTittle',item.columnName)
          // 栏目
          this.$store.commit('setMenuType','lanmu')
          // 首页数据
          this.$store.commit('setMenulist',item)
          // 传递id
          this.$router.push({path:'/chooseList',query:{categoryId:item.id,columnType:item.columnType,columnAttr:item.columnAttr,columnStyle:item.columnStyle}})
        }
    },
    // 动态图片
    image(url){
      return require('@/assets/'+url+'.png')
    },
}
}
</script>

<style lang="less" scoped>
  @import '../home.less';
</style>